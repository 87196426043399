@import '~app/mixins';

.editor {
  width: 950px;

  .body {
    max-height: 800px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 14px;

    .criteria {
      width: 490px;

      .summarySection {
        margin-bottom: 20px;

        .sectionHeader {
          margin-bottom: 0;
        }
      }

      .sectionHeader {
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 6px;
        margin-bottom: 14px;
        border-bottom: 2px solid #eee;
      }

      .section {
        display: inline-block;
        vertical-align: top;

        + .section {
          margin-left: 50px;
        }

        .sectionCaption {
          margin-bottom: 10px;
          color: $color-blue-darkest;
        }
      }

      .subText {
        font-size: 10px;
      }

      .part,
      .partDouble {
        display: inline-block;
        vertical-align: top;
        margin: 8px 0 7px;
        width: 240px;

        .partCaption {
          margin-bottom: 3px;
          line-height: 18px;
        }
      }

      .partDouble {
        width: 480px;
      }

      .formElementContainer {
        display: inline-block;
        vertical-align: top;
        margin-top: 4px;
        margin-right: 12px;

        input[disabled] {
          background-color: green;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .includeExclude {
        .formElementContainer {
          width: 80px;
          margin-right: 0;
        }
      }

      .listingType {
        > div {
          display: flex;

          .formElementContainer {
            width: 75px;
            margin-right: 0;

            &:nth-child(2) {
              width: 92px;
            }

            &:last-child {
              width: 60px;
            }
          }

          &:last-child {
            margin-top: 10px;

            .sectionCaption {
              margin-bottom: 5px;
            }
          }
        }
      }

      .homeFeatures {
        .formElementContainer {
          margin-right: 0;

          &:nth-child(2),
          &:nth-child(5) {
            width: 65px;
          }

          &:nth-child(3),
          &:nth-child(6) {
            width: 78px;
          }
        }
      }

      .foreclosureStatusDropdown {
        right: -26px;
      }

      .propertyTypeDropdown {
        width: 300px;
        max-height: 380px;
      }

      .inputContainer {
        display: inline-block;
        vertical-align: top;
        width: 85px;

        &.wide {
          width: 130px;
        }

        &.extraWide {
          width: 197px;
        }

        &.fullWidth {
          width: 475px;
        }
      }

      .selectContainer {
        width: 120px;
      }

      .boldCaption {
        font-weight: bold;
      }

      .divider {
        display: inline-block;
        vertical-align: top;
        margin: 0 8px;
        line-height: 26px;
      }

      .checkboxOnly {
        vertical-align: bottom;
        margin-right: 25px;
        width: auto;
      }

      @media (min-width: 1450px) {
        .formElementContainer {
          margin-right: 30px;
        }
      }
    }

    .summary {
      color: $color-blue-dark;
      font-size: 13px;

      .summaryInner {
        background-color: $color-dirty-white;
        min-height: 100px;
        border-radius: 15px;
        padding: 15px;
        width: 430px;

        .noCriteria {
          font-style: italic;
          color: $color-grey;
          text-align: center;
          margin-top: 20px;
          font-size: 14px;
        }

        .summaryHeader {
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          padding-bottom: 5px;
          border-bottom: 1px solid $color-blue-darkest;
          margin-bottom: 10px;
          white-space: nowrap;

          .summaryTitle {
            margin-right: 53px;
            font-weight: bold;
          }

          .summaryLocation {
            text-overflow: ellipsis;
            font-size: 15px;
            margin-top: -1px;

            &.noLocation {
              font-style: italic;
            }

            &:not(.noLocation) {
              overflow-x: hidden;
            }
          }
        }
      }
    }
  }

  .footer {
    text-align: center;
    padding-top: 10px;

    button + button {
      margin-left: 20px;
    }
  }
}

.viewer {
  padding: 25px 30px;
  color: $color-grey;
  min-width: 900px;
  position: relative;

  .header {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 2px solid $color-grey-light;
    margin: 0 auto 20px;
    width: 80%;
  }

  .footer {
    font-size: 11px;
    text-align: center;
    color: $color-grey-light;
    padding-top: 10px;
    border-top: 2px solid $color-grey-light;
    margin: 20px auto 0;
    width: 80%;

    span {
      color: #f16722;
    }
  }

  .warning {
    white-space: pre-wrap;

    span {
      margin-right: 3px;
    }
  }

  .disclaimer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .warning {
      width: 470px;
      margin-top: 16px;
      margin-bottom: 30px;

      span {
        color: #b40000;
      }
    }

    svg.iconWarning {
      width: 100px;
      height: 100px;
      fill: #b40000;
    }
  }

  &.editMode {
    .header {
      width: 100%;
    }
  }
}

.fields {
  max-height: 540px;
  overflow-y: auto;
  column-count: 2;

  * {
    -webkit-column-break-inside: avoid; /* Chrome, Safari */
    page-break-inside: avoid;           /* Theoretically FF 20+ */
    break-inside: avoid-column;
  }

  .field {
    display: flex;
    margin: 2px;

    .fieldLabel {
      width: 175px;
      font-weight: bold;
      margin-right: 4px;
    }

    .fieldValues {
      display: flex;
      flex: 1;
      align-items: flex-end;

      div + div {
        margin-left: 4px;
      }

      .fieldValue {
        &.valueDesc {
          font-style: italic;
        }

        &.rangeValue {
          &:after {
            font-style: italic;
            margin-left: 4px;
          }

          &.fullRange:after {
            content: 'to';
          }

          &:not(.fullRange) {
            &.numberValue {
              &.minValue:after {
                content: 'or more';
              }

              &.maxValue:after {
                content: 'or less';
              }

              &.quantityValue.maxValue:after {
                content: 'or fewer';
              }
            }

            &.dateValue {
              &.minValue:after {
                content: 'or later';
              }

              &.maxValue:after {
                content: 'or earlier';
              }
            }
          }
        }
      }
    }

    .fieldClear {
      cursor: pointer;
      height: 15px;
      width: 15px;
      visibility: hidden;
    }

    &:hover {
      .fieldClear {
        visibility: visible;
      }
    }
  }

  &.editable {
    column-count: 1;

    .field {
      .fieldLabel {
        width: 135px;
        cursor: pointer;
      }

      .fieldValues {
        .fieldValue {
          cursor: pointer;
        }
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 8px;
  }
}

// todo it's modal duplicate
button.btnClose {
  $size: 21px;

  position: absolute;
  right: 20px;
  top: 12px;
  width: $size;
  height: $size;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  .iconClose {
    width: $size;
    height: $size;
  }
}
